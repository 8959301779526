
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';
import   helperFile   from '../../../helpers/helper';

import VueGoogleAutocomplete from 'vue-google-autocomplete';

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Cluster",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    VueGoogleAutocomplete
    
  },
  data() {
    return {
      title: "Add Cluster",
      items: [
        {
          text: "Cluster",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      restaurants:[],
      disabled: true,
     // selected:"0",
      selectButton:"Select",

      // table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: 'multi',
      selected: [],
      dishData:"",
      

      fields: [
        {
          key: "selected",
          
          sortable: false,
        },
        
        
        {
          key: "restaurantName",
          sortable: true,
        },
        {
          key: "address",
          
          sortable: true,
        },
        
      ],

      posts:{
        addressInput:"",
        address:"",
        restaurantID:"",
     
      }
    };
  },

    validations: {
    posts: {
      addressInput: {
        required,
      },
      clusterName: {
        required,
      },
     
      adminName:{
        required,
      },
      adminPhone:{
        required,
      },
      adminEmail:{
        required,
      }
    },
     selected:{
        required,
      },
  },
  computed:{
    rows() {
      return this.tableData.length;
    },
    //return {selected.length}
  },
  methods:{
/**
  * When the location found
  * @param {Object} addressData Data of the found location
  * @param {Object} placeResultData PlaceResult object
  * @param {String} id Input container ID
  */
  getAddressData: function (addressData, placeResultData) {
      this.address = addressData;
      if(placeResultData){
        //console.log(placeResultData);
       
          this.posts.addressInput = placeResultData.formatted_address;
          this.posts.latitude = placeResultData.geometry.location.lat();
          this.posts.longitude = placeResultData.geometry.location.lng();

          this.nearbyRestaurants = helperFile.getNearByRestaurantOrCafe;
          this.nearbyRestaurants(this.posts.latitude,this.posts.longitude,1);  // flag =>restaurant only, 2=>cafeteria
      
      }
      
      
  },

    openModal(){
    //alert();
    
      this.$root.$emit("bv::show::modal", "modalBranches");
      this.getNearByRestaurant(this.posts.latitude,this.posts.longitude);
  },
      onRowSelected(items) {
        this.selected = items;
         if(this.selected.length){
           
              this.selectButton = "Selected "+this.selected.length;
        }
       // console.log(this.selected);
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },

    submitClusterForm(e){
   // alert(this.posts.delTime);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
          let formData = new FormData();  
  
            formData.append('branchIDs',JSON.stringify(this.selected));
            formData.append('addressInput',this.posts.addressInput);
            formData.append('address',this.posts.address);
            formData.append('state',this.posts.state);
            formData.append('city',this.posts.city);
            formData.append('pincode',this.posts.pincode);
            formData.append('country',this.posts.country);
            formData.append('latitude',this.posts.latitude);
            formData.append('longitude',this.posts.longitude);
            formData.append('clusterName', this.posts.clusterName);
            formData.append('branchManName', this.posts.adminName);
            formData.append('branchManEmail', this.posts.adminEmail);
            formData.append('branchManPhone', this.posts.adminPhone);
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);
    
      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addCluster",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                  this.$router.push({name: 'categoryTable'}); 
              },2000);
        
         // console.warn(result);

      })
     .catch((error) => {
       this.loading = false;
                       alert(error.response.data.data);
                    });
      }  
    },

     ///// data table methods
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  

  },
mounted(){
  this.$refs.address.focus();
    
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
     <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Cluster Registered Successfully!
    </b-alert>
  </div>
<form @submit.prevent="submitClusterForm" method="post">

        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                     <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-clusterName-input">Cluster Name *</label>
                        <input type="text" class="form-control" id="formrow-clusterName-input" name="clusterName" 
                        v-model="posts.clusterName"
                        :class="{
                            'is-invalid': submitted && $v.posts.clusterName.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.clusterName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.clusterName.required"
                            >This value is required.</span
                          >
                        </div>
                    </div>
                               <div class="row">
                  <div class="mb-3 col-md-6">
                    <label>Address   
                      
                      
                        
                    </label>
                      <vue-google-autocomplete
                              ref="address"
                              id="map"
                              classname="form-control"
                              placeholder="Please type your address"
                              v-on:placechanged="getAddressData"
                              country="in"
                              types="establishment"
                              v-model="posts.address"
                            
                          >
                          </vue-google-autocomplete>

                        
                      </div>
                      <div v-if="this.posts.addressInput" class="col-md-4" style="border: 1px solid #d8d8d8;padding: 10px 28px;">
                       
                          <p>{{this.posts.addressInput}}
                              
                               <span style="position: absolute;right: 3px;bottom: -7px;">
                              <button type="button" v-b-modal.modal-standard class="btn" 
                              style="color: #F3766A !important;font-size: 15px;font-weight: 600;">
                                    <i class="uil uil-plus"></i> Edit
                              </button>
                              </span>
                              </p>
                      </div>
                      
                </div>
                  <b-modal
                id="modal-standard"
                title="Restuarant Address"
                title-class="font-18"
                @ok="addNewAddress();">
               <div class="mb-3">
               <label>Address</label>
               
                  <textarea
                    v-model="posts.addressInput"
                    class="form-control"
                    name="textarea"
                    rows="3"
                  ></textarea>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-pincode-input">Pincode</label>
                          <input type="text" class="form-control" id="formrow-pincode-input" v-model="posts.pincode"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-city-input">City</label>
                          <input type="text" class="form-control" id="formrow-city-input" v-model="posts.city"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-state-input">State</label>
                          <input type="text" class="form-control" id="formrow-state-input" v-model="posts.state"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-country-input">Country</label>
                          <input type="text" class="form-control" id="formrow-country-input" v-model="posts.country"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-latitude-input">Latitude</label>
                          <input type="text" class="form-control" id="formrow-latitude-input" v-model="posts.latitude"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-longitude-input">Longitude</label>
                          <input type="text" class="form-control" id="formrow-longitude-input" v-model="posts.longitude"
                         
                          >
                    </div>
                </div>

              </b-modal>
                <div class="mb-3 col-md-6 row">
                      <div class="col-md-6">
                          <label class="form-label" for="formrow-firstname-input">Select Restaurant *</label><br>
                         <button type="button" class="btn btn-themeOrange  w-md waves-effect waves-light " :disabled="disabled" @click="openModal();"
                         :class="{
                                    'is-invalid': submitted && $v.selected.$error,
                                      }">
                           {{ selectButton }}
                           </button> 
                      </div>
                        
                    
                                    <!-- <p class="col-md-6" :class="{
                                    'is-invalid': submitted && $v.selected.$error,
                                      }">
                                      Selected Restaurant Branches:<br>
                                      {{ selected.length }}
                                     
                                    </p> -->
                                      <div
                                          v-if="submitted && $v.selected.$error"
                                          class="invalid-feedback"
                                          >
                                          <span v-if="!$v.selected.required"
                                              >This value is required.</span
                                          >
                                          </div>
        
                    </div>
                    <b-modal
                          id="modalBranches"
                          title="Restuarant Branches"
                          title-class="font-18"
                          
                        size="lg"
                        >
                                        <!-- Table -->
                      <div class="table-responsive mb-0">
                   
                        <b-table
                        
                          striped hover
                          outlined
                          bordered
                        
                          :items="tableData"
                          :fields="fields"
                          :select-mode="selectMode"
                          ref="selectableTable"
                          selectable
                          @row-selected="onRowSelected"
                          responsive
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                          
                          
                        >
                        
                          <!-- <template slot="Action" slot-scope="data">
                          <input class="form-check-input" type="checkbox" id="branchselected" v-model="posts.selectedBranchID" @change="selectedBranch(data.item.branchName);">
                          </template> -->
                          <!-- Example scoped slot for select state illustrative purposes -->
                      
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true"><i class="far fa-check-square"></i></span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true"><i class="far fa-square"></i></span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
              
            
            
                          <!-- <template v-slot:cell(Action)="row">
                          <b-form-group>
                          
                              <input class="form-check-input" type="checkbox" id="branchselected"
                              :value="row.item.branchName" v-model="selectedBranchID" @change="selectedBranch();">
                          </b-form-group>
                        </template> -->


                        
                        </b-table>
              <p>
                <b-button size="sm" class="me-2" @click="selectAllRows">Select all</b-button>
                <b-button size="sm" @click="clearSelected">Clear selected</b-button>
              
              </p>
              
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>

              </b-modal>
                   <div class="row">
                    <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManager-input">Manager Name *</label>
                        <input type="text" class="form-control" id="formrow-branchManager-input"  v-model="posts.adminName"
                        :class="{
                            'is-invalid': submitted && $v.posts.adminName.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.adminName.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.adminName.required"
                            >This value is required.</span
                          >
                        </div>
                       
                  </div>

                   <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManPhoneNO-input">Manager Mobile No *</label>
                        <input type="text" class="form-control" id="formrow-branchManPhoneNO-input"  v-model="posts.adminPhone"
                         :class="{
                            'is-invalid': submitted && $v.posts.adminPhone.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.adminPhone.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.adminPhone.required"
                            >This value is required.</span
                          >
                        </div>
                       
                       
                  </div>
              </div>
            <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManagerEmail-input">Manager Email ID *</label>
                        <input type="text" class="form-control" id="formrow-branchManagerEmail-input" v-model="posts.adminEmail"
                        :class="{
                            'is-invalid': submitted && $v.posts.adminEmail.$error,
                          }" >
                        <div
                          v-if="submitted && $v.posts.adminEmail.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.adminEmail.required"
                            >This value is required.</span
                          >
                        </div>
            </div>

              <div class =" row" style="float:right;">
                    <b-spinner v-if="loading"
                    class="m-2 col-3"
                    variant="primary"
                    role="status"
                  ></b-spinner>
                  <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
              </div>
        </div>
               
            </div>
        </div>

</form>


  

</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


